@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  
  
}

#cremeBg{
  background-color: rgba(249,239,227);
}
header {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: solid 1px rgb(255, 255, 255,0.5);
  z-index: 2;

}

.custom-navbar {
  width: 100vw
 

}


/* Make sure the Navbar items also use full width when collapsed */
.custom-collapse {
  width: 100%;
}

.logo {
  width: 180px;
  height: auto;

}

.logo-img {
  width: 70%;
  height: auto;
}
#l-zucchetti{
  width: 120%;
}

.logo-text {
  color: #fff;
  font-size: 2rem;
}

.logo-text:hover {
  color: #cdba6d;
}

#nav {
  width: 100%;
  display: flex;
  justify-content: space-between;

}

#ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  /* border: 2px solid green; */
}

.nav-options {
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  border: none;
  text-decoration: none;
}



.nav-options:hover {
  color: #f2542d;
  cursor: pointer;
}
#nav-dropdown {
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  border: none;
  text-decoration: none;
}


#nav-dropdown:hover {
  color: #f2542d;
  cursor: pointer;
}

.custom-dropdown-menu .dropdown-menu {
  background-color:  rgba(0, 0, 0, 0.3) !important; /* Use !important to ensure it overrides Bootstrap styles */
  border: none; /* Optional: remove the border if you want a clean look */
}
.custom-dropdown-menu .dropdown-item {
  color: #fff; /* Optional: set text color */

}
.custom-dropdown-menu .dropdown-divider {
  background-color: #fff; /* Color for the divider */
  height: 1px; 

}
.custom-dropdown-menu .dropdown-item:hover {
  color: #fff; /* Optional: set text color */
}
.custom-dropdown-menu .dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;  /* Optional: set hover color */
}

.custom-navbar .navbar-toggler-icon {
  background-image: url('./assets/bars-solid.svg'); /* Set your custom icon path */
  background-size: contain; /* Ensure the icon fits well */
}
#search-icon-button {
  align-self: flex-start;
  border: none;
  border-color: transparent;
}

#search-icon-button:hover {
  background-color: transparent;
}
#search-icon {
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;
}

#search-icon:hover {
  color: #0057ff;
}

#menu-icon {
  display: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.modal-container {
  width: 100%;
  height:100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 110px;
 

}

.main-text-container {
  width: 100%;
  height:100%;
 top:0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-color: transparent;
  align-items: center;
  padding-left: 100px;
  background-color: rgba(0, 0, 0,.1);
  background: linear-gradient(to right,  rgba(0, 0, 0, 0.4) 0%, rgba(168, 168, 168, 0.1) 100%);
  backdrop-filter: saturate(180%) blur(3px); 


}





.home-container {
  background-image: url('./assets/background\ \(2\).jpg');
  background-size: cover;
  background-position: 42% 50%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
 
  background-color: #000;
  
}



.main-text-container  span {
  font-size: 3rem;
  position: relative;
  font-family: "Poppins", sans-serif;;
  text-transform: uppercase;
  border-right: .15em solid orange; /* The typwriter cursor */

  letter-spacing: 5px;
  overflow: hidden;
  color: #fff;
  animation: 
  typing-main 3s steps(60, end),
  blink-caret-main 1s step-end infinite;
  white-space: nowrap; /* Keeps the content on a single line */
  overflow: hidden; 
}

@keyframes typing-main {
  from { width: 0 }
  to { width: 70% }
}

/* The typewriter cursor effect */
@keyframes blink-caret-main{
  from, to { border-color: transparent }
  50% { border-color: white; }
}


.presentation-container {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-top: 150px;
  padding: 10px;
  color: #fff;
  z-index: 1;
}

.presentation-container h3 {
  letter-spacing: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.presentation-container h1 {
  font-size: 5rem;
  text-align: center;
}

.presentation-container span {
  color: #cdba6d;
}

.presentation-container p {
  text-align: center;
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}


.presentation-container button {
  width: 150px;
  height: 50px;
  background-color: #cdba6d;
  font-family: "Poppins", sans-serif;
  color: #000;
  font-weight: 500;
  border: 2px solid #c8b050;
  font-size: 1rem;
  cursor: pointer;

}

.presentation-container button:hover {
  background-color: transparent;
  color: #fff;

}

.destaques-logo-img {
  width: 60%;
  height: auto;

}
.destaques-container {

  width: 100%;
  height: auto;
  position: static;

  overflow-x: hidden;
}
.with-child{
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 200px 0px;
 
}
#bg-left{
  position: absolute;
  top:100px;
  width: 5vw;
  left: 50px;
}
#bg-right{
  top:650px;
  position: absolute;
  right: 50px;
  width: 5vw;
  
}
.destaques-container-title {
 
  width: 80%;
  height: 100%;
  display: flex;
  
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.destaques-container-title #title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: 50vw;
}

#destaques-container-divider {
  width: 70%;
  border-color:#282e91;
  align-self: center;
}

.destaques-container-title h2 {
  
 font-size: 1.3rem;
  letter-spacing: 6px;
  font-weight: 500;
  color: #282e91;


}

.destaques-container-title h1 {
  marging: 0;
  font-size: 3rem;
  color: #f2542d;


}
.arrow-options-container{
display: flex;
justify-content: space-between;
width: 60%;
margin-top: 100px;
padding-top: 30px;


}
#arrow-options{
  color: #f2542d;
  cursor: pointer;
  font-size: 30px;
}
#arrow-options:hover{

  color: #282e91;
    cursor: pointer;
  }


.destaques-content {
  width: 70vw;
  height: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  color:   rgba(0, 0, 0, 0.5);;
  border-top-left-radius: 40px;
  border-top-right-radius: -10px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  width: 20vw;
  height: 60vh;

}
.content:hover{
  box-shadow: 3px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
 
  
}
.content-padding{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
  padding: 0 50px;
  width: 100%;

}
.content-container-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 25vh;
  width: 100%;
}

#c-zucchetti{
  width: 100%;
  
}
#animation-Text{
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #0072bc; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .05em;
  text-align: left; /* Adjust as needed */
  animation: 
    typing 4.5s steps(20, end),
    blink-caret .75s step-end infinite;
    animation-iteration-count: infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 75% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #0072bc; }
}

#square-1 {
  background-color: #FFF;
 

}

#square-1 i {
  color: #f2542d;;
  font-size: 3rem;
}

.destaques-span {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
}

.destaques-paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}

.destaques-paragraph-balack-backgorund{
  border-radius: 30px;
  background-color: #000;
  padding: 5px 10px;
  color:#FFCC00;
  margin-left: 5vw;
  text-align: left;

}
.destaques-paragraph-balack-backgorund:hover{

  background-color: #FFCC00;
 cursor: pointer;
  color:#000;
 

}
.carousel-nav{
  color: #282e91;
  cursor: pointer;
  font-size: 20px;
}
.carousel-nav:hover{
  color:   #f2542d;
}



#destaques-span-gold {
  color:   #f2542d;;

}

#square-2 {
  background-color: #FFF;

}

#gavel-icon {
  font-size: 3rem;

}

#square-3 {
  background-color: #FFF;

}

#square-3 i {
  color:#f2542d;
  font-size: 3rem;
}

.divider {
  border: none;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 5px 0;
  width: 60%;
}
#d-take{
  border-color:#FFCC00
}
#d-zucchetti{
  border-color:#282e91
}

#divider-white {

  border-top: 3px solid rgba(0, 0, 0,0.2);

}


.welcome-container {
  width: 100%;
  height: auto;
  padding:50px 0px;
  background-color: #f2542d;
  display: flex;
  justify-content: center;
}

.signature-container {
  width: 80%;
  height: 100%;
  display: flex;

}

.signature-content {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;


}

.signature-text {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
  padding: 0 10px;
  margin-bottom: 10px;
  
}

.signature-title {
  width: 100%;
}

.signature-title h1 {
  font-size: 2.5rem;
  color: #fff;
}

.signature-title h5 {
  letter-spacing: 3px;
  font-weight: bold;
  color: #282e91;
  

}

.signature-text span {
  font-family: "Poppins", sans-serif;
  color: white;
  
}

.signature-text p {
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.signature-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
 
}

.signature-button-container button {
  width: 150px;
  height: 50px;
  background-color: #282e91;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  border: 2px solid #fff;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;

}

.signature-button-container button:hover {
  background-color: transparent;

}

.signature-button-container img {
  width: 100px;
  height: auto;
  margin-top: 0;

}

.attorney-container {
  width: 50%;
  
  display: flex;
  align-items: center;

}
.hub-container {
  width: 50%;
 
  background-image: url('./assets/hub\ de\ negócios.png');
  background-size: 70%;
  background-position: center center;
  background-repeat: no-repeat;
  
}

.attorney-container img {
  margin-left: 50px;
  width: 15vw;
  height: 40vh;
  object-fit: cover;

  

 

}
#img-1{
  border-radius: 40%;
 
}
#img-2{
  border-radius: 40%;
  object-position: 100% 20%;
  
  
}

.our-services-container {
  width: 100%;
  height: auto;
  background-color:  #282e91;;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 100px 0px;
}

.our-services-title {
  height: 30%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.our-services-title h2 {
  marging: 0;
  font-size: 1.3rem;
  letter-spacing: 6px;
  font-weight: 500;
  color: #fff;


}

.our-services-title h1 {
  marging: 0;
  font-size: 3.5em;
  text-align: center;
  color: #fff;


}

.our-services-content {
  width: 80%;
 
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 20px;
  padding:50px 0px;
}

#our-services-divider {
  width: 5%;
  background-color:#fff
}

.our-services-square {
  display: flex;
  flex-direction: column;
  padding: 20px  10px;
  width: 100%;
  height: auto;
  row-gap: 10px;
 

  border-radius: 15px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);

}

.our-services-icon {
  display: flex;
  width: 100%;
  
  justify-content: center;
  align-items: center;
  column-gap: 30px;
 


}


.our-services-text {
  
  height: 100%;
display: flex;
justify-content: center;
  text-align: center;
 align-items: center;



}

#service-icon {

  font-size: 2.6em;

  color: #f2542d;


}

#service-icon:hover {

  font-size: 3em;

  color: #FFF;
  cursor: pointer;

}

.service-title {
  color: #FFF;
  font-size: 1.3em;
  font-weight: 700;

}

.service-title:hover {
  color: #f2542d;
  cursor: pointer;
}

.service-description {
  color: #fff;
  font-family: "Poppins", sans-serif;
width: 80%;
  font-size: 1em;

}

.our-testimonials-container {
  width: 100%;
  height: 100vh;
  background-color:  #fff;;
  display: flex;
  flex-direction: column;
 align-items: center;
 padding-bottom: 50px;

}
.padding {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
 align-items: center;
 justify-content: center;


}

.padding-flicking {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
 align-items: center;
 justify-content: center;


}
.padding-contact {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
 align-items: center;
 justify-content: center;


}

.padding-reverse {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
 align-items: center;
 justify-content: center;


}
.our-testimonials-title {

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.our-testimonials-title h2 {
  marging: 0;
  font-size: 1.3rem;
  letter-spacing: 6px;
  font-weight: 500;
  color: #282e91;
  


}

.our-testimonials-title h1 {
  marging: 0;
  font-size: 3.5em;

  color: #f2542d;
}

#our-testimonial-divider{
  width: 10%;
  border-color:#282e91

}


.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

}

.marvel-device .screen {
  text-align: left;
}

.screen-container {
  height: 100%;
  
}


/* Chat */

.chat {
  height: calc(100% - 69px);

}

.chat-container {
  height: 100%;
  
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.user-bar:after {
  content: "";
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;

}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  max-height: 80vh;
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 0;
  
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  box-shadow: inset 0 10px 10px -10px #000000;
  overflow-x: hidden;
  max-height: 70vh;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
          transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
          transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
          transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
          transform: perspective(800px) rotateY(-179.9deg);
}

.message:first-child {
  margin: 16px 0 8px;
}

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.message.received .sender{
  text-align: start;
  color: lightblue;
  font-size: 0.8rem;
  font-weight: bold;

}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 100%;
  z-index: 2;
 
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
  
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 48px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 48px;
}

.conversation-compose .photo:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px;
}

.conversation-compose .photo i {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
  margin-left: 5px;
}

/* Small Screens */

.chat-top-bar{
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 8vh;
}

.our-testimonial-content {
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0 10px;
  margin-top: 10px;
 
  justify-content: center;
}

.our-testimonial-square {
  display: flex;
  width: 100%;
  height: 80px;

}
#chat-sending{
  justify-content: flex-end;
  height: 60px;
  
}

#chat-receiving{
  justify-content: flex-start;
}


#chat-1{
  border-top-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgba(220,248,198, 1);

}
#chat-2{
  border-top-left-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  text-align: start;
  
}
#check-icon{
  color: rgb(52,183,241);

}
.wpp-avatar{

    width: 5%;
    height: 25%;
    border-radius: 50%;
    background-size: cover;
    background-position: 50% 50%;
  
  
}

.receiving-container{
  display: flex;
  flex-direction: row;
}


.our-testimonial-text {
  width: 70%;
 padding: 10px 30px 20px 30px;
 text-align: start;
 border: 2px solid #fff;
  background-color: #fff;
}

.testimonial-description {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 400;
}
#hour{
  font-size: 0.9em;
  text-align: end;
}

.testimonial-title {
  color: #f2542d;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 5px;
}

.testimonials-container {
  width: 100%;
  height: 150vh;
  display: flex;
  flex-direction: column;
}

.testimonials-title {
  height: 30%;
  width: 100%;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.testimonials-title h2 {
  color: #5c6f85;
  margin: 0;
  font-size: 1.3rem;
  letter-spacing: 6px;
  font-weight: 500;
}

.testimonials-title h1 {
  color: #c8b050;
  margin: 0;
  font-size: 3.5rem;
}

.testimonials-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 600px;
  padding: 0 120px;
}

.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding: 10px 40px 40px 40px;
  background-color: #f8f8f9;
  box-shadow: -5px 5px 7px 0 rgba(0, 0, 0, 0.15);
}

.testimonial-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: 50% 50%;
}

#testimony-icon {
  font-size: 3rem;
  color: #c8b050;
  opacity: 0.5;
}

.testimony {
  text-align: center;
  color: #5c6f85;
  font-size: 1.2rem;
  font-family: "Poppins", serif;

}

.testimonial-name {
  font-size: 1.5rem;
  color: #2e353d;
}


.testimonial-profession {
  font-size: 1.3rem;
  color: #c8b050;
  font-family: "Poppins", serif;

}



.form-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: rgba(249,239,227);


}

.form-img {
 background-color:#282e91 ;
  width: 20%;
  height: 100%;
  
}
.align-form{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 80%;
  height: auto;


}

.form-content {
  background-color: rgba(249,239,227);
  width: 90%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}

.form-title {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
}


.form-title h2 {
  margin: 0;
  font-size: 1.3rem;
  letter-spacing: 6px;
  font-weight: 500;
  color: #000;
}

.form-title h1 {
  margin: 0;
  font-size: 3.5rem;

  color: #282e91;
}

#form-divider {
  width: 15%;
  margin-top: 20px;
}

.form-description {
  text-align: justify;
  width: 70%;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 5000;
  color: #000;
}

.form-inputs {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
 

}

.first-inputs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;

}

.input-container {
  display: flex;
  align-items: center;
  min-height: 50px;
  width: 49%;
}

.user-input {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: #2e353d;
  padding: 0 10px;
  border:none;
  border-radius: 15px;
  outline: none;
  border: 1px solid black;

  

}


#user-phone-input {
  margin-top: 10px;
  width: 100%;
}

#user-message-input {
  height: 150px;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 15px;

  color: #2e353d;
  width: 100%;
}

#user-message {
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 90%;
  resize: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: #2e353d;
  padding: 10px;
  border:none;
    outline: none;

}

.submit-button {
  width: 30vw;
  height:5vh;
  background-color: #fff;
  color: #282e91;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 30px;
  border: 2px solid #282e91;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 20px;

}

.submit-button:hover {

  background-color: transparent;
  color: #f2542d;


}
.submit-button:disabled{
  color: black;
  border-color: grey;
  background-color: grey;
  cursor: default;
}

.information-container {
  width:25%;
  height: 70vh;
  position: absolute;
  top: 20vh;
  right: 10vw;
  display: flex;
  justify-content: flex-end;
  
}

.information-content {
  width: 100%;
  height: 100%;
  background-color:#f2542d ;
  border-radius: 10% 0%;
  display: flex;
  justify-content: flex-start;
  padding-top: 5vh;
flex-direction: column;
  align-items: center;
  align-self: center;
  
}
.information-content h2 {
  font-size: 2rem;
  color: #282e91;
}
.info-description{
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 90%;
 
  justify-content: space-around;
}
.info-item{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0px 0px 0px 0px;
}

.info-item-icon{
  font-size: 2em;
    display: flex;
    width: 10%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    color: #282e91;
  }

  .info-item span{
    color: #fff;
  }

footer {
  width: 100%;
  height: auto;
  background-color: #f2542d;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer-content{
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;


  column-gap: 10px;
  
}
.footer-content img{
  width: 5vw;
  

  
}
.footer-content span{
 
  color:#fff;
  font-size: 1.1rem;

  
}
.footer-content .icon{
 
  color:#fff;
  font-size: 2rem;
  margin-left: 20px;

  
}


.footer-content .info-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  align-self:center;
  column-gap: 10px;
 
  
}

.about-text {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 70px;
}

.about-text h2 {
  color: #fff;
  margin: 0;
  font-size: 1rem;
  letter-spacing: 6px;
  font-weight: 700;
}

.about-text p {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 1.3rem;
  line-height: 2;
  font-weight: 500;
}

.socail-media {
  display: flex;
  column-gap: 20px;
}

.social-media i {
  font-size: 1.3rem;
  color: #fff;
  cursor: pointer;
}

#facebook-icon:hover {
  color: #007bff;
}

#telegram-icon:hover {
  color: #17a2b8;
}

#linkedin-icon:hover {
  color: #007bff;
}

#instagram-icon:hover {
  color: #e83e8c;
}

#youtube-icon:hover {
  color: #dc3545;
}

.theme-posts {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;

}

.theme-posts h2 {
  color: #fff;
  margin: 0;
  font-size: 1rem;
  letter-spacing: 6px;
  font-weight: 700;
  margin-bottom: 40px;
}


.posts {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  display: flex;
}

.post-img {
  width: 20%;
  height: auto;

}

.post-content {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 80px 0 10px;

}

.post-content p {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 1.2rem;

  font-weight: 700;
}

.post-content p:hover {

  color: #000;

}

.post-date {
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 15px;

}

#clock-icon {
  color: #fff;
}

.post-date span {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
}

.copyright {
  width: auto;
 
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.copyright span {
  font-family: "Poppins", sans-serif;
  color: #fff;
  /* letter-spacing: 6px; */
  font-size: 1rem;
}
#infoIcon{
  width: 60%;
  color:#282e91

}

@keyframes flash {
  0% {
    opacity: 1;
    background-color: green;
    border-radius: 50%;
   
  }
 
  100% {
    opacity: 1;
    border-radius: 50%;
 

  }
}

.flashing {
  animation: flash 4s infinite;
}

.floating-image {
  cursor: pointer;
  position: fixed; /* Fixed position to stay on the screen */
  right: 2%; /* Align to the right edge */
  top: 92%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  margin: 10px; 
  width: 4%;/* Optional: Add some margin from edges */
  z-index: 2;
}
.vertical-carousel {
  width: 50vw; /* Set carousel width */
  height: 90vh; /* Set carousel height */
  margin: auto; /* Center the carousel */
  background: transparent; /* Optional background */
  overflow: hidden; /* Hide overflow */
}
.horizontal-carousel {
  width: 40vw; /* Set carousel width */
  height: auto; /* Set carousel height */
  margin: auto; /* Center the carousel */
  background: transparent; /* Optional background */
  overflow: hidden; /* Hide overflow */
}

.carousel-panel {
  width: 100%; /* Full width of the carousel */
  height: 100%; /* Full height of the carousel */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-panel img {
  width: 100%; /* Responsive images */
  height: auto;
  border-radius: 30px; /* Maintain aspect ratio */
}

.carrouselVideo{
  width: 70%; /* Responsive images */
  height: auto;
  border-radius: 30px;

}
.instaLogo{
  width: 5vw;
  cursor: pointer;
}
@media(max-width:1440px){
  .welcome-container {
    width: 100%;
    height: 100vh;
  
  }
 
  #bg-right{
    top:750px;
    position: absolute;
    right: 50px;
    width: 5vw;
    
  }
  .content{
    padding: 0 90px;
    
  }

  #square-3{
    padding: 0 60px;
  }
.testimonials-cards-container{
  padding: 0 100px;
}
.post-content{
  padding: 0 55px 0 10px;
}

  }

  @media(max-width:1280px){
    .with-child{
      padding: 100px 0px;
    }
    .padding {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: row;
     align-items: center;
     justify-content: center;
     column-gap: 20px;
    
    
    }
    .padding-flicking {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: row;
     align-items: center;
     justify-content: center;
     column-gap: 20px;
    
    
    }
    .padding-contact {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
     align-items: center;
     justify-content: center;
     column-gap: 20px;
    
    }
    .padding-reverse {
      width: 80%;
      height: auto;
      display: flex;
      flex-direction: row;
     align-items: center;
     justify-content: center;
     column-gap: 20px;
    
    
    }
    #bg-left{
      position: absolute;
      top:120px;
      width: 5vw;
      left: 50px;
    }
    #bg-right{
      top:780px;
      position: absolute;
      right: 50px;
      width: 5vw;
      
    }

    .main-text-container {
      padding-left: 50px;
    }

    
    @keyframes typing-main {
      from { width: 0 }
      to { width: 70% }
    }
    
    
    .main-text-container  span {
      font-size: 2.5rem;
      
  
    }
 
    .destaques-container {

     
      height: auto;
     
    }
    .welcome-container{
      height: auto;

    }
    .our-testimonials-container {
    
      height: auto;
      padding: 50px 0px;
   
    
    }

    .form-container {
   
      height: 100vh;

     
    
    }

    
   header{
    padding: 0 40px;
   }
 

   .testimonials-cards-container{
    padding: 0 110px;
   }
   .form-inputs {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
   
  }
  .info-item-icon{
      
    width: 15%;
  
  }


  .hub-container {
    width: 50%;
   
    background-size: 90%;
    
  }

  .information-container {
    width:40%;
    height: 50vh;
    position: absolute;
    top: 20vh;
    right: 0vw;
    display: flex;
    justify-content: flex-end;
    
  }
  
    }
  
    @media(max-width:1024px){
      
      
      #bg-left{
        position: absolute;
        top:110px;
        width: 7vw;
        left: 50px;
      }
      #bg-right{
        top:780px;
        position: absolute;
        right: 50px;
        width: 7vw;
        
      }


      @keyframes typing-main {
        from { width: 0 }
        to { width: 90% }
      }
      header{
        padding: 0 0px;
       }
       
    
     
      
       .content{
        padding: 0 40px;
       }
       #square-3{
        padding: 0 40px;
      }
      .testimonials-cards-container{
        padding: 0 50px;

      }

      .form-content {
        width: 80%;
        padding: 0px 50px;
    
      }
      .align-form{
      
        justify-content: flex-start;
        
      
      }
      
      .floating-image {
      
        width: 10%;/* Optional: Add some margin from edges */
      }
      .with-child{
        padding: 150px 0px;
           
          }
          .arrow-options-container{
            width: 80%;
        
        
            
            }
    }


    @media(max-width:991px){
      .form-content {
        width: 90%;
        padding: 0px 0px;
    
      }
      .align-form{
      
        justify-content: flex-end;
        
      
      }
      .custom-dropdown-menu .dropdown-item {
        color: #fff /* Optional: set text color */;
        text-align: center;
      }
      .custom-dropdown-menu .nav-options {
        color: #fff /* Optional: set text color */;
        text-align: center;
        width: 50vw;
      }
      .main-text-container {
        padding-left: 50px;
      }
      .main-text-container  span {
        border-right: .1em solid orange;
       font-size: 2rem; /* Keeps the content on a single line */
        
      }
      @keyframes typing-main {
        from { width: 0 }
        to { width: 100% }
      }
      
      
    
      .custom-navbar .navbar-collapse {
        position: absolute;
        top: 185px; /* Adjust this value based on your Navbar height */
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5); /* Match Navbar background color */
        width: 100%;
        display: block;
        padding:10px 0px;
        height: 45vh;
        
      }
      .nav-options {
        font-size: 1.5rem;
        margin: 10px 0px;

      }
      #nav-dropdown{
        font-size: 1.5rem;

      }
      #sub-option{
        color: #f2542d;
        text-align: center;
        font-size: 1.4rem;
        align-self: center;
        justify-content: center;
        align-content: center;
      }
    
      .custom-navbar .navbar-toggler {
        margin-right: auto;
      }
    
      .custom-navbar .navbar-nav {
        width: 100%;
        text-align: center; /* Center align text and items in the navbar */
      }
    
      .custom-navbar .nav-link {
        display: block;
        padding: 15px; /* Add padding to each nav item for better touch experience */
      }
    }
    @media(max-width:835px){
      .padding {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
       align-items: center;
       justify-content: center;
      
      
      
      }
      .padding-flicking {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: row;
       align-items: center;
       justify-content: center;
      
      
      
      }
      .padding-contact {

        display: flex;
        flex-direction:column;
       align-items: center;
       justify-content: center;
       height: auto;
      padding: 0px 0px 50px 0px;
      }

      .padding-reverse {
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column-reverse;
       align-items: center;
       justify-content: center;
      
      
      
      }
      #bg-left{
        display: none;
        }
        #bg-right{
          display: none;
          
        }

      .main-text-container  span {
        white-space: normal;
       font-size: 2rem; /* Keeps the content on a single line */
        animation: none;
        border: none
      }

      
      .custom-navbar .navbar-collapse {
        top: 95px; /* Adjust this value based on your Navbar height */
     
      }
      nav{
        display: none;
      }
      #menu-icon{
        display: block;
      }
      .home-container{
        background-position:  70% 5%;
        align-items: center;

      }

      .presentation-container{
        width: 90%;
        margin-top: 0;
      }

     
      
    
      .content{
        height: 300px;
        row-gap: 10px;
        padding: 0 20px
      }

      .welcome-container{
        height: auto;

      }
      

      .signature-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        
        
      }
      .signature-content{
        margin-top: 5vh;
      
        display: flex;
        flex-direction: column;     
        justify-content: center;
        padding: 0;
        width: 80%;
      }
      .signature-text{
        width: 100%;
        height: 100%;
        row-gap: 30px;
        padding: 0px 0px;
        align-items: start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      
  .hub-container {
    width: 80vw;
    height: 50vh;
   
    background-size: 90%;
    
  }
      .attorney-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-around; 
      }
      .attorney-container img {
        margin-left: 0px;
        width: 20vw;
        height: 25vh;
      }
      #img-1{
        width: 30%;
      }
      #img-2{
       margin-left: 5px;
        width: 30%;
        
        
      }


     
    
      .our-testimonials-container {
        display: flex;
        flex-direction: column;
       align-items: center;
       height: auto;
       }
       

      
      
      .our-testimonials-title h1 {
       
        font-size: 3.5em;
      text-align: center;
        color: #f2542d;
      }
      .form-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
       
      
      }

      .information-container {
        width: 90%;
        height: auto;
        position: static;
        display: flex;
        justify-content: center;
        border-radius: 50px;
        align-self: flex-end;
    
      }
      .information-content {
        width: 100%;
        height: 30vh;
  justify-content: flex-end;
  border-radius: 50px 0px 50px 0px;
      }

      .info-description{
        height: 100%;
        justify-content: space-evenly;
        width: 90%;
      }
      .info-description h2{
        margin-left: 8.5vw;
      }
      .form-img{
        height: 30%;
        width: 100%;
      }
      .form-content{
        width: 100%;
        height: auto;
        padding:120px 40px 60px 40px;
       
      }

      footer{
        height: auto;
        display: flex;
        align-items: center;
        font-size: 1rem;
      }
      .copyright span {
      
        letter-spacing: 1px;
        font-size: 0.7rem;
      }

   
      .about-text{
        width: 100%;
        height: 40%;
      }

      .theme-posts{
        width: 100%;
        height: 45%;
      }

      .post-content{
        padding: 0 140px 0 10px
      }
      .destaques-container-title {
      
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .with-child{
    padding: 50px 0px;
       
      }

      .destaques-container-title #title {
      
        width: 80%;
      }

      .destaques-content {
  
padding: 50px 0px;}

.vertical-carousel {
  width: 70vw; /* Set carousel width */
  height: 70vh; /* Set carousel height */
  margin: auto; /* Center the carousel */
  background: transparent; /* Optional background */
  overflow: hidden; /* Hide overflow */

}
.horizontal-carousel {
  width: 80vw; /* Set carousel width */
  height: auto; /* Set carousel height */
  margin: auto; /* Center the carousel */
  background: transparent; /* Optional background */
  overflow: hidden; /* Hide overflow */
}
        
.footer-content{
  width: 100%;

  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;


  row-gap: 10px;
  
}

    }

    @media(max-width:768px){

    
      .signature-container{
        height: auto;
      }
      .signature-content{
        height: auto;
      }
    
      .padding {
        width: 100%;
     
      
      
      }
      .padding-flicking {
        width: 100%;
        flex-direction: column;
        row-gap: 10px;
      
      
      }
      .padding-contact {
        height: auto;
        width: 100%;
        

      }
      #bg-left{
      display: none;
      }
      #bg-right{
        display: none;
        
      }

      .main-text-container  span {

        font-size: 2.5rem; /* Keeps the content on a single line */
        letter-spacing: 1px;
        
      }

      .destaques-container-title {
      
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .custom-navbar .navbar-collapse {
        top: 90px; /* Adjust this value based on your Navbar height */
        height: 50vh;
      }
      .logo-img {
        width: 80%;
        height: auto;
      }
     
      .destaques-content {
  
        display: flex;
        justify-content: center;
       flex-direction: column;
         row-gap: 30px;
        align-content: center;
        align-self: center;
        align-items: center;
        
      }
      .our-testimonials-title {

       padding: 20px 0px;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
        color:   rgba(0, 0, 0, 0.5);;
        border-top-left-radius: 40px;
        border-top-right-radius: -10px;
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;
        box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
        width: 80%;
        height: 40vh;
      
      }
.welcome-container{
  height: auto;
}
.page {
  width: 80%;
}
.information-content{
  height: 80vh;
  
}
.info-description{
  height: 80%;

 
}
.form-container{
  height: auto;

    
}
.form-content{

  height: 90%;
  
}
.information-container {

  height: 100%;

 

    }

    .information-content {
      width: 100%;
      height: 40vh
      
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      padding: 0px 0px 0px 50px;
    }

    footer{
      height: auto;
    }

  }
    @media(max-width:601px){
     
      .custom-navbar .navbar-collapse {
        top: 90px; /* Adjust this value based on your Navbar height */
        height: 60vh;
      }
      .destaques-container {
        width: 100%;
        height: auto;
        margin: 0;
        
  position: static;
        display: flex;
        justify-content: center;
      }

      .destaques-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        row-gap: 90px;
        align-content: center;
        align-self: center;
      }
      
      
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 5px;
        color:   rgba(0, 0, 0, 0.5);;
        border-top-left-radius: 40px;
        border-top-right-radius: -10px;
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;
        box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
        width: 70vw;
        height: auto;
     
      }

      .content-padding{
     
        row-gap: 10px;
        padding: 0 50px;
        width: 100%;
      
      }
      .content-container-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20vh;
        width: 100%;
        
      }
      .destaques-span {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
      }
      
      .welcome-container{
        height: auto;
        padding-bottom: 50px;
      }

      .our-testimonials-container {
        width: 100%;
        height: auto;
        
      
      }

      .page {
        width: 70%;
        
      }
  
     

     

      .our-services-title h1{
        text-align: center;
      }

      #our-services-divider{
        width: 30;
        color:#0000
      }

     
     
   
      
      .information-content{
        height: 50vh;
        border-radius: 0px;
        justify-content: center;
       
      }
      .info-description{
        height: 100%;
        justify-content: space-evenly;
        width: 90%;
      }
   
      .information-container {
        width: 100%;
        height: 100%;
      
       
      }
      .info-item-icon{
      
          width: 15%;
        
        }
        .form-container{
          height: auto;
          background-color: rgba(249,239,227);
        }
        
        .form-content{
          width: 100%;
          height: 100%;
          background-color: rgba(249,239,227);
          padding: 50px 20px;
       
        }
        
        .align-form{

          width: 90%;
          height: auto;
        
        
        }
        .info-item{
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          padding: 0px 0px 0px 40px;
        }
        .form-img{
          display:none;
        }
    }
    @media(max-width:500px){
      .vertical-carousel {
        width: 80vw; /* Set carousel width */
        height: 60vh; /* Set carousel height */
        margin: auto; /* Center the carousel */
        background: transparent; /* Optional background */
        overflow: hidden; /* Hide overflow */
      
      }
      .user-input {
        font-size: 0.8rem;
      }

 #user-message {
        font-size: 0.8rem;
      }
      header {
        position: fixed;
        top: 0;
        height: 80px;}
      .logo {
        width: 120px;
        height: auto;
      
      }
      .nav-options {
        font-size: 1.2rem;
       
      }
      .floating-image {
    
        width: 15%;/* Optional: Add some margin from edges */
      }
     
      .custom-navbar .navbar-collapse {
        top: 80px; /* Adjust this value based on your Navbar height */
        height: 45vh;
      }
      .main-text-container {
        padding-left:30px;

        justify-content: center;
      
        align-items: center;

      }
  
      .destaques-container {
        width: 100%;
        height: auto;
     
        margin: 0;
  position: static;
        display: flex;
        justify-content: center;
      }

      .destaques-content {
        width: 80%;
        height: auto;
        display: flex;
        justify-content: center;
        row-gap: 0px;
        align-content: center;
        align-self: center;
        
      }

      .destaques-container-title #title {
        width: 80%;
      }
      .carousel-nav{
     
        font-size: 1rem;
      }
      #arrow-options{
        font-size: 2rem;
      }

    
    .arrow-options-container{
    width: 100%;
    padding-top: 0px;

    
    }
      .destaques-container-title h2 {
        font-size: 1.2rem;
      }
      
      .destaques-container-title h1 {
        font-size: 3rem;
      
      
      }
      #img-1{
        width: 40%;
      }
      #img-2{
       margin-left: 2px;
        width: 40%;
        
        
      }
  

      .content-padding{
     
        row-gap: 10px;
        padding: 0 50px;
        width: 100%;
      
      }
      .content-container-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20vh;
        width: 100%;
        
      }
      .destaques-span {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
      }
      
      .welcome-container{
        height: auto;
        padding-bottom: 50px;
              width: 100%;
      }
      .signature-container {
        width: 100%}
      .our-testimonials-container {
        width: 100%;
       height: auto;
        
      
      }

      .our-testimonials-title h2 {

        font-size: 1.3rem;
      
        
      
      
      }
      
      .our-testimonials-title h1 {

        font-size: 3rem;
      

      }

      .page {
        width: 90%;
        
      }
  
   

      .our-services-title{
        margin-top: 120px;
        padding-top: 20px;
        height: 15%;
        width: 100%;
      }

      .our-services-title h1{
        text-align: center;
      }

      #our-services-divider{
        width: 30;
        color:#0000
      }

    
      .form-container {
      
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        overflow: hidden;
        height: auto;

      
      }
      .submit-button{
        margin-top: 0px;
        font-size: 1rem;
        width: 30%;
      }
      .form-title {
        height: auto;
       
      }
     
      .form-title h2{
        font-size: 1.3rem;
      }
      .form-title h1{
        font-size: 3rem;
      }
      
      .information-content{
        height: 40vh;
        border-radius: 0px;
      }
      .info-description{
        height: 100%;
        justify-content: space-evenly;
 
        width: 90%;
      }
   
      .information-container {
        width: 100%;
        height: 40%;
       border-radius: 0px;
      }
      .info-item{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 0px 0px 0px 20px;
      }
      .info-item-icon{
      
          width: 15%;
        
        }
        .form-container{
        height: auto;
          padding-top: 10px;
          
        }
        
        .form-content{
          width: 100%;
          height: 100%;
          row-gap: 10px;
          padding: 50px 20px;

        }
        
        .form-inputs {

          height: 300px;
        }
        .align-form{

          width: 100%;
          height: auto;
        
        
        }
        .form-description {
         
          font-size: 1.1rem;
         
        }

        .form-img{
          display:none;
        }
    }
    @media(max-width:415px){
      .footer-content .info-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        
        align-self:center;
        text-align: center;
        column-gap: 5px;
       
        
      }
      .footer-content img{
        width: 10vw;
        
      
        
      }
      
.main-text-container {
  font-size: 1.8rem;
  
}


      .custom-navbar .navbar-collapse {
        height: 50vh;
      }
  
      .destaques-container {
        width: 100%;
        height: auto;
        
  position: static;
        display: flex;
        justify-content: center;
      }

   
      .destaques-container-title h2 {
        font-size: 1rem;
      }
      
      .destaques-container-title h1 {
        font-size: 2.4rem;
      
      
      }
      
      .welcome-container{
        height: auto;
        padding-bottom: 50px;
      }

      .our-testimonials-container {
        width: 100%;
        height: auto;
        padding: 50px 0px;        
      
      }

      .our-testimonials-title h2 {

        font-size: 1.1rem;
      
        
      
      
      }
      
      .our-testimonials-title h1 {

        font-size: 2.6rem;
      

      }

      .chat {
        width: 90%;
        
      }
      .actions{
        display: none;
      }
  
     
      .form-title h2{
        font-size: 1.1rem;
      }
      .form-title h1{
        font-size: 2.6rem;
      }
      
      .information-content{
        height: 40vh
        
      }
      .info-description{
        height: 100%;
        justify-content: space-evenly;
 
        width: 90%;
      }
      .information-container {
        width: 100%;
        height: auto;
       
      }
      .form-description {
        width: 100%;
       
      }
        .form-container{
          height: auto;
       

        }
        
        .form-content{
          width: 100%;
          height: auto;
          row-gap: 1px;

        }
        
        .form-inputs {

          height: 300px;
        }

        .form-img{
          display:none;
        }

        #infoIcon{
          width: 60%;
          color:#282e91
        }
       
        
    }

    @media(max-width:380px){
      
    
      .logo {
        width: 100px;
        height: 100%;

      }
      .main-text-container  span {
        font-size: 2.3rem;
        padding: 0px 0px 0px 0px;
      }

      .destaques-container {
        width: 100%;
        height: auto;
        /* padding: 20px 20px 20px 20px; */
        margin: 0;
  position: static;
        display: flex;
        justify-content: center;
      }

   
      .destaques-container-title h2 {
        font-size: 1rem;
      }
      
      .destaques-container-title h1 {
        font-size: 2.3rem;
      
      
      }
      
      .welcome-container{
        height: auto;
      }

      .our-testimonials-container {
        width: 100%;
        height: auto;
        
      
      }

      .our-testimonials-title h2 {

        font-size: 1.1rem;
      
        
      
      
      }
      
      .our-testimonials-title h1 {

        font-size: 2.3rem;
      

      }

      .page {
        width: 95%;
        
      }
  
     
      .form-title h2{
        font-size: 1.1rem;
      }
      .form-title h1{
        font-size: 2.3rem;
      }
      
      .information-content{
        height: auto
        
      }
      .info-description{
        height: 100%;
        justify-content: space-evenly;
 
        width: 90%;
      }
      .information-container {
        width: 100%;
        height: auto;
       
      }
      .form-description {
        width: 100%;
       
      }
        .form-container{
          height: auto;
       

        }
        
        .form-content{
          width: 100%;
        }
        
        .form-inputs {

          height: 300px;
        }

        .form-img{
          display:none;
        }

        #infoIcon{
          width: 60%;
          color:#282e91
        }
      

        
    }