@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.modal-container-take {
    width: 100%;
    height:100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 110px;
    background-color: #FFCC00;
    mix-blend-mode: multiply;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  
  
  
  }

  #blue{
    color: #282e91;
   }


  .home-container-take {
    background-image: url('../assets/takeCapa.png');
    background-size: cover;
    background-repeat: no repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   position: relative;
    
  }

  .take-capa-container{

   
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    height:100vh;
    align-items: center;

  }

  .take-capa-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40vw;
 
    
  }
  .take-capa-text h1{
   width: 80%;

   font-size: 3.5rem;
  }
  .take-capa-text div{
    background-color: black;
    border-radius: 15px;
    color:  #FFCC00;
    width: 60%;
    text-align: center;
  }
  .take-capa-text button{
    background-color: black;
    border-radius: 15px;
    color:  #FFCC00;
    font-size: 1.5rem;
    width: 40%;
  }
  .take-capa-text button:hover{
    color: white;
  }


  .take-capa-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    width: 35vw;
    height: 100vh;
  }
  .take-capa-image img{
    width: 100%;
    padding-top: 50px;
  }

  
  #sobreTake {
    background-color: rgba(249,239,227);
    justify-content: flex-start;
  }

  #sobreTake span{
    color:  #000;
  }
  .mao-celular-container {
    width: 50%;
   
    background-image: url('../assets/maocelular.png');
    background-size: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    
  }
  #maocelular{
    border-radius: 10px;
  }
  #sobreTake-container{
    width: 90%;
    justify-content: space-between;
    column-gap: 50px;
 
  }
  @media(max-width:1280px){
    .take-capa-text h1{
      font-size: 2.2rem;
    }
    .our-services-title h2{
      text-align: center;
      font-size: 1.2rem;
    }
    .our-services-title h1{
      font-size: 3rem;
    }
   
    
.our-services-content {
  width: 80%;
 
  height: auto;
  display: grid;

  column-gap: 30px;
  row-gap: 20px;
  margin-top: 20px;
}



#service-icon {

  font-size: 2.5em;

  color: #f2542d;


}

#service-icon:hover {

  font-size: 2.5rem;

  color: #FFF;
  cursor: pointer;

}

.service-title {
  
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.service-title:hover {
  color: #f2542d;
  cursor: pointer;
}

.service-description {
  
  font-family: "Poppins", sans-serif;

  font-size: 1rem;

}


  }

  @media(max-width:1024px){
    .take-capa-container{

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height:100vh;

      
    }
    .take-capa-text{
 
      width: 70vw;
      height: 40vh;
    
   
      
    }

    .take-capa-text h1{
 
   
      font-size: 3rem;
     }
     .take-capa-image{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50vw;
      height: auto;
    }
    
  }
  @media(max-width:835px){
    .take-capa-container{

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height:100vh;

      
    }
    .take-capa-text{
 
      width: 80vw;
      height: 40vh;
    
   
      
    }

    .take-capa-text h1{
 
   
      font-size: 2.8rem;
     }
  
    .take-capa-image{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50vw;
      height: auto;
    }
    #sobreTake-container{
      flex-direction: column-reverse;
      row-gap: 30px;
    }
    .mao-celular-container {
      width: 90vw;
     height: 40vh;
     background-size: 80%;
  
      
    }

.our-services-container {
  height: auto;
 
}
    .our-services-content {
      width: 90%;
      height: auto;
      display: grid;
      column-gap: 30px;
      row-gap: 10px;
      margin-top: 10px;
    }
    .our-services-square {
      display: flex;
      flex-direction: column;
      padding: 20px 10px ;
      width: 90%;
      height: auto;
      row-gap: 10px;


      border-radius: 15px;
      box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
    
    }
    
    .our-services-icon {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
     
    
    

    }

 
    
    .service-title {
      color: #FFF;
      font-size: 1.1rem;
      font-weight: 700;
      margin-bottom: 15px;
     
    }
    #service-icon {

      font-size: 2.4em;
    
      color: #f2542d;
    
    
    }
  }
  @media(max-width:610px){

    .take-capa-text h1{
 
   
      font-size: 2.5rem;
     }
     .our-services-container {
      height: auto;
    }
    
     .our-services-content {
      width: 95%;
      height: auto;
      display: grid;
      column-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
   
    }
    .our-services-title{
      width: 90%;
      height: auto;
      margin-top: 10px;
      margin-bottom: 50px;
      padding-top: 20px;
    
    }

    .service-title {
     text-align: center;
      font-size: 1.2rem;
   
    }
    .our-services-square {
   
      width: 100%;
      height: auto;
  
    
    }
    .service-description {
      width: 100%;
    }

    #service-icon {

      font-size: 2.6em;
    
   
    
    
    }
  
  }
  @media(max-width:550px){
    .take-capa-container{

  
      justify-content: flex-end;

      padding-bottom: 50px;

      
    }
  
    .take-capa-text h1{
 
   
      font-size: 2.3rem;
     }
     .our-services-title h2 {
   
      font-size: 1rem;
 
    
    
    }
    
    .our-services-title h1 {
   
      font-size: 2.5em;
      
    
    
    } .our-services-content {
    
      column-gap: 0px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
      height: auto;
      width: 90%;
 
    }

    .our-services-container {
  
      height: auto;
    }
  
    #service-icon {

      font-size: 2.3rem;
    
   
    
    
    }

  }
  @media(max-width:500px){

    .home-container-take {
      background-image: url('../assets/takeCapa.png');
      background-size: cover;
      background-position: 10% 50%;
   
      
    }
    .take-capa-container{

      justify-content: center;

      padding-bottom: 0px;

      
    }
    .take-capa-text{
 
      width: 100vw;
      padding: 0px 0px 0px 50px;

    
   
      
    }
    .take-capa-text h1{
 
   
      font-size: 2.2rem;
     }

     .take-capa-image{

      width: 70vw;
      height: auto;
    }
    .our-services-container {
      height: auto;
      padding-bottom: 50px;
    }
    .our-services-content {
    
      column-gap: 0px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
      height: auto;
      width: 100%;
 
    }
    .our-services-square {
   
      width: 80%;
      height: auto;
    
    
    }

    .our-services-square h2{
      font-size: 1rem;
    }
    .our-services-square >div >h1{
      font-size: 2.5rem;
    }
    .our-services-square h3{
      font-size: 1.2em;
    }
    .our-services-square span{
      text-align: center;
       font-size: 0.9rem;
       
     }

   
  }
  @media(max-width:400px){

    .home-container-take {
   height: auto;
      
    }
    .take-capa-text{
 
      width: 100vw;
      padding: 0px 0px 0px 30px;

    
   
      
    }
    .take-capa-text h1{
 
   
      font-size: 1.6rem;
     }

     .take-capa-image{

      width: 60vw;
      height: auto;
    }
    .take-capa-text button{
    
      font-size: 1.2rem;
    
    }
    .our-services-container {
      height: auto;
    }
  

  }