.btn {
    background-color: #f2542d;
    color: #fff;
    border: none;
    outline: none;
    font-size: 1rem;
    border-radius: 10px;
    padding: 10px 10px;
    /* margin: 0 0.5rem ; */
    width: 9rem;
  }
  
  @media(max-width:500px){
    .btn {
  
      font-size: 0.8rem;
      width: 7rem;
    }
    
  }