
#white{
  color:white;
}
.home-container-zuchetti {
    background-image: url('../assets/computer.jpg');
    background-size: cover;
    background-position: 50% 40%;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #000;
    
  }
  .main-text-container-zuc {
    width: 100%;
    height:100%;
   top:0px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content:center;
    border-color: transparent;
    align-items: flex-start;
    padding-left: 100px;
    background-color: rgba(0, 0, 0,.1);
    background: linear-gradient(to right,  rgba(0, 0, 0, 0.4) 0%, rgba(168, 168, 168, 0.1) 100%);
    backdrop-filter: saturate(180%) blur(3px); 
  
  
  }

  .main-text-container-zuc  span {
    font-size: 3rem;
    position: relative;
    font-family: "Poppins", sans-serif;;
    text-transform: uppercase;
  
    letter-spacing: 5px;
    overflow: hidden;
    color: #fff;
    animation: 
    typing-main 1.5s steps(40, end),
    blink-caret-main 1s step-end infinite;
    white-space: nowrap; /* Keeps the content on a single line */
    overflow: hidden; 
  }
  
  @keyframes typing-main {
    from { width: 0 }
    to { width: 60% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret-main{
    from, to { border-color: transparent }
    50% { border-color: white; }
  }
  #sub-title{
    margin-left: 20%;
    border: none;
  }

  .logos-capa{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90%;
    column-gap: 20px;
  }
  .logos-capa img{
    width: 10%;
    height: auto;
  }

    .zuc-sobre {
    width: 100%;
    height: 100vh;
  
  }
  .zuc-sobre-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(205, 205, 205, 0);
    background-image: url('../assets/Zuc-sobre.png');
    background-size: cover;
    background-position: 90% 0%;
    background-repeat: no-repeat;
    position: relative;
    justify-content: center;
    background-color: rgba(249,239,227);
  }
  .zuc-sobre-cert-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(205, 205, 205, 0);
    background-image: url('../assets/zuc-cert.png');
    background-size: cover;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    justify-content: center;
    background-color: rgba(255,255,255);
  }

  .zuc-sobre-image {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(205, 205, 205, 0);
    background-image: url('../assets/Zuc-sobre.png');
    background-size: contain;
    background-position: 100% 0%;
    background-repeat: no-repeat;
    position: relative;
    justify-content: center;
    

  }
 
  .zuc-sobre-content {
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

  }
  .zuc-sobre-cert-container .zuc-sobre-content{
    height: 100%;
    width: 80%;
    display: flex;
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .zuc-sobre-text {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
 
    row-gap: 30px;
 
   

  }
    
  .zuc-sobre-title {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
   
  }
  
  .zuc-sobre-title h1 {
    font-size: 4.5rem;
    color: #ffff;
    width: 90%;
  }

  
  
  .zuc-sobre-title h5 {
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 2.5rem;
    color: white;
    
  
  }
  #clipLogo{
    width: 40%;
  }
  
  .our-services-container-zuc {
    width: 100%;
    height: auto;
    padding: 50px 0 50px 0;
    background-color:  #f2542d;;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
#blue{
color:#282e91;
}
#orange{
  color:#f2542d;
}
#our-services-divider-zuc{
  width: 30%;
  color:#282e91
}
.our-services-content-zuc {
  width: 50%;
  height: 50%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 15px;
  column-gap: 30px;
  margin-top: 30px;
}
.our-services-content-zuc img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.our-services-content-cert {
  width: 50%;
  height: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2,1fr);
  row-gap: 15px;
  column-gap: 30px;
  margin-top: 30px;
}
.our-services-square-zuc {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25vh;
  position: relative;
  z-index: 1;
  border-radius: 15px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);

}

.our-services-square-zuc img{
  height: 100%;
  width: 100%;
}

.our-services-square-zuc .bg {
  position: absolute;
  z-index: -1;
 background-color: #fff;
 top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
opacity: 0.3;
 
}
.our-services-text-zuc {
   position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;

}

.our-services-text-zuc h1 {
  position: absolute;
  font-size: 2rem;

}
.our-services-text-zuc span {
 color: #fff;

}

#service-icon-zuc {

  font-size: 2.8rem;

  color: #282e91;


}
#service-icon-lider {

  font-size: 2rem;

  color: #029b1c;


}
#service-icon-lider:hover {


  color:#282e91;


}

#service-icon-zuc :hover {

  font-size: 3em;

  color: #FFF;
  cursor: pointer;

}

.our-services-square-zuc button{
  width: 80%;
  border-radius: 30px;
  border-color: #282e91;
  background-color: #282e91;
  font-size: 1rem;
  padding: 5px 0px 5px 0px;
  color: #ffff;
 
}

.our-services-square-zuc button:hover  {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.service-title-zuc {
  color: #000000;
  font-size: 1.1rem;
 
  margin-bottom: 15px;
}

.destaques-container-zuc {

  width: 100%;
  height: 100vh;
  position: static;
  background-color:#ffff;
  
  overflow-x: hidden;
}

.zuc-details {
  width: 100%;
  height:auto;
 

}

.zuc-details-container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(205, 205, 205, 0);
flex-direction: column;
  background-size: contain;
  background-position: 90% 0%;
  background-repeat: no-repeat;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: rgba(249,239,227);
  padding: 0px 0px 50px 0px;
  
}



.zuc-details-content {
  margin-top: 50px;
  height: 100%;
  width: 80%;
  display: flex;
  padding: 0px 0px 0px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.zuc-details-image {
  width: 100%;
  height: auto;
  display: flex;
  background-color: rgba(205, 205, 205, 0);
 
  background-size: contain;
  background-position: 100% 0%;
  background-repeat: no-repeat;

  justify-content: center;
  

}

.zuc-details-text {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  row-gap: 15px;


}
.lider-details-text {
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 5px;


}
#alignStart{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.zuc-details-title {
  
  width: 80%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;

}
.lider-digital-title {
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  row-gap: 50px;
  padding: 50px 0px;
}
.lider-digital-title #title {
  
  width: 80%;

  
}

.lider-digital-title h1{
  font-size: 3rem;
  color: #f2542d;
text-align: left;

text-align: center;
padding: 10px 0px;

}
#divider-lider{
  border-bottom: 4px solid #282e91;
  width: 60%;
  align-self: center;
  justify-content: center;
  margin: auto;
}
.lider-digital-title button{
  width: 50%;
  font-weight: 600;
  align-self: center;
  padding: 10px 0px;
  border-radius: 30px;
  background-color: #282e91 ;
  color: white;

}
.lider-digital-title button:hover{
  box-shadow: 10px 10px 15px grey;


}

.lider-details-title {
  
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;


}

.zuc-details-title span {
 color: #000;
 font-size: 1.1rem;

}

.zuc-details-text h1{
font-size: 1.8rem;
width: 80%;
color:#282e91 ;


}

.zuc-details-text p{
  font-size: 1.1rem;
  width: 80%;
  color:#000 ;
  
  }

  .zuc-details-text h3{
    font-size: 1.4rem;
    width: 80%;
    color:#f2542d ;
    
    }
    .zuc-details-text span{
      font-size: 1rem;
      width: 80%;
      color:#000 ;
      
      }

      #whiteBg{
        background-color: #FFF;
      }

      #alignStart{
        display: flex;
        justify-content: flex-start;
      }
      .zuc-details-text button{
        width: 35%;
        border-radius: 30px;
        border-color: #282e91;
        background-color: #282e91;
        font-size: 1rem;
        padding: 8px 0px 8px 0px;
        color: #ffff;
        
      }
      #btnCenter{
        align-self: center;
      }
      
      .zuc-details-text button:hover  {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
      }

      .zuc-images{
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 50px;
      
        width: 10%;
      }
.carouselShow{
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;

  margin: auto;



}

.carouselShow img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  height: auto;

}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;

  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: #282e91;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #282e91;
}
.lider-flicking{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 50px 0px ;

  
}

.lider-flicking .capa{
  width: 40%; /* Responsive images */
  height: auto;
  border-radius: 30px;}

  .lider-flicking .horizontal-carousel {
    width: 40%; /* Set carousel width */
    height: auto; /* Set carousel height */
    margin: 0px; /* Center the carousel */
    background: transparent; /* Optional background */
    overflow: hidden; /* Hide overflow */
  }

      @media(max-width:1280px){

        
.our-services-text-zuc h1 {

  font-size: 1.8rem;

}


#service-icon-zuc {

  font-size: 2.6rem;

  color: #282e91;


}

#service-icon-zuc:hover {

  font-size: 2.8em;

  color: #FFF;
  cursor: pointer;

}

.our-services-square-zuc button{

  font-size: 0.8rem;
 
 
}

.service-title-zuc {
  
  font-size: .9rem;
 
}

        .our-services-content-zuc {
          width: 60%;
         
        }

        .our-services-square-zuc {
      
          height: 30vh;
       
        
        }
      
        
        .zuc-details-title span {
         font-size: 1rem;
        
        }
        
        .zuc-details-text h1{
        font-size: 1.6rem;
      
        
        
        }
        
        .zuc-details-text p{
          font-size: 0.9rem;
        
          
          }
        
          .zuc-details-text h3{
            font-size: 1.2rem;
      
            
            }
            .zuc-details-text span{
              font-size: 0.8rem;
              
              
              }
              .zuc-details-text button{
                width: 35%;
               
                font-size: 0.8rem;
             
                
              }

              #service-icon-zuc {

                font-size: 2.5rem;
              
              
              
              }
              
      }

      @media(max-width:1024px){
        .carouselShow{
          width: 90%;
         
        
        
        
        }

        .carouselShow img {
      
          width: 70%;
      
        
        }
        .zuc-sobre-container {
     
          background-position: 0% 100%;
   
          
        }
        .zuc-sobre-content {
          height: 100%;
          width: 80%;
          display: flex;
          padding: 100px 0px 0px 0px;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          align-items: flex-start;
      
        }
        .zuc-sobre-text {
          width: 100%;
          height: 100%;
          justify-content: center;
         
        }
        .our-services-content-zuc{
          width: 70%;
        }
         .our-services-square-zuc {
          height: 15vh;
        }
        .zuc-details-content {
         
          width: 90%;
     
         
        
        } 
      }
      @media(max-width:835px){

        .lider-digital-title {
  
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 50px;
          padding: 50px 0px;
         
        }
        .lider-details-text {
          width: 80%;
          height: auto;
          display: flex;
          flex-direction: column;
          row-gap: 5px;
        
        
        }
        .lider-digital-title #title {
          
          width: 80%;
        
          
        }
        
        .lider-flicking .capa{
          width: 45%; /* Responsive images */
        }
        
          .lider-flicking .horizontal-carousel {
            width: 45%; /* Set carousel width */
            margin: 0px;
          }
        .zuc-sobre-cert-container {
          width: 100%;
          height:100%;
   
          background-position: 0% 100%;
        
        }

        .zuc-sobre-cert-container .zuc-sobre-title {
          width: 100%;
          margin-top: 70px;
          padding-left: 50px;
          
      
        }

        .zuc-sobre-cert-container  #clipLogo {
          width: 45%;
        }

        .zuc-sobre-container {
     
          background-position: 0% 100%;
   
          
        }
        .zuc-sobre-content {
          height: 100%;
          width: 100%;
          display: flex;
          padding: 60px 0px 0px 0px;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
      
        }
        .zuc-sobre-text {
          width: 100%;
         
        }


        .zuc-sobre-title {
          width: 100%;
          margin-top: 50px;
          padding-left: 50px;
          
      
        }

        .zuc-sobre-title h1 {
          font-size: 3rem;
          color: #ffff;
          width: 80%;
        }
      
        
        
        .zuc-sobre-title h5 {
          letter-spacing: 3px;
          font-weight: bold;
          font-size: 1.3rem;
          
        
        }
        #clipLogo{
          width: 35%;
        }
      
        .our-services-content-cert {
          width: 80%;
        }
        .our-services-content-zuc {
          width: 80%;
         
        }
        .our-services-content-cert .our-services-square-zuc {
          height: 15vh;
        }

        .our-services-square-zuc {
      
          height: 20vh;
       
        
        }
        .our-services-title #H2center {
    
          text-align: center;        
        
        }
        .zuc-details-content {
         
          width: 90%;
     
         
        
        } 
        .zuc-details-text {
          width: 60%;}
      .zuc-images{
     

      
        width: 30%;
      }
      #column-reverse{
        justify-content: column-reverse;
      }

      }
      @media(max-width:610px){
        .lider-flicking .capa{
          width: 80%; /* Responsive images */
        }
        
          .lider-flicking .horizontal-carousel {
            width: 80%; /* Set carousel width */
            margin: 0px;
          }
        .carouselShow{
          width: 100%;
         
        
        
        
        }

        .carouselShow img {
      
          width: 80%;
      
        
        }

        .zuc-sobre-title h1 {
          font-size: 2.6rem;
          color: #ffff;
          width: 80%;
        }
      
        
        
        .zuc-sobre-title h5 {
          letter-spacing: 3px;
          font-weight: bold;
          font-size: 1.1rem;
          
        
        }
        #clipLogo{
          width: 40%;
        }
        .our-services-content-cert {
      
          row-gap: 15px;
          justify-content: center;
       
        }
        .our-services-content-zuc {
          width: 80%;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(3,1fr);
          row-gap: 15px;
          justify-content: center;
       
        }
        .our-services-content-cert .our-services-square-zuc {
          height: 18vh;
        }
        .our-services-square-zuc {
          padding: 10px 10px 10px 10px;
          height: 20vh;
       
        
        }

        .our-services-text-zuc {
      
         width: 100%;
        
       
       }
        .our-services-title #H2center {
    
          text-align: center;        
        
        }
        .our-services-container-zuc {
       
          padding: 0px 0px 50px 0px;

      
        }

        #s-title-zuc {
         
          width: 90%;
          margin-top: 50px;
          padding-top: 20px;
          text-align: center;
        
        }
        #s-title-zuc h2 {
   
          font-size: 1.1rem;
  
        
        
        }
        
        #s-title-zuc h1 {

          font-size: 3.3rem;
        
  
        
        
        }
        #service-icon-zuc {

          font-size: 2.2rem;
        
          color: #282e91;
        padding-bottom: 5px;
        
   
      }
        .our-services-square-zuc button{
          width: 70%;
          border-radius: 30px;
       
          font-size: 0.8rem;
      
         
        }
#column-not-reverse{
  flex-direction: column;
}
.zuc-details-content {

  flex-direction: column-reverse;
  align-items: center;
  row-gap: 50px;

 

}
.zuc-details-text {
  width: 90%;
  justify-content: center;
  align-items: center;
  


}
.zuc-images{



  width: 40%;
}
#alignStart{

  display: flex;
  justify-content: center;
  align-items: center;
}
#j-column{
  flex-direction: column;
}
 
      }

      @media(max-width:550px){
        .zuc-sobre-title h1 {
        
          width: 90%;
        }
        .service-title-zuc {
  
          font-size: .8rem;
         
        }
        .our-services-square-zuc button{
          width: 60%;
          border-radius: 30px;
       
          font-size: 0.6rem;
      
         
        }
     }
     @media(max-width:500px){
      .zuc-sobre-cert-container    .zuc-sobre-text {
        width: 100%;
        height: 75%;
        justify-content: flex-start;
       
      }
        

      .zuc-sobre-container {
     
        background-position: 10% 0%;
 
        
      }  .zuc-sobre-cert-container {
        width: 100%;
        height:100%;
 
        background-position: 70% 100%;
      
      }

      .zuc-sobre-cert-container .zuc-sobre-title {
        width: 100%;
        margin-top: 0px;
        padding-left: 0px;
  
        
    
      }
      
      .our-services-content-zuc {

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4,1fr);
        
     
      }
      .zuc-sobre-cert-container  #clipLogo {
        width: 60%;
      }

 
      .zuc-sobre-title a {
        font-size: 0.5rem;
      }
      .our-services-content-cert .our-services-square-zuc {
        height: 16vh;
      }

      .our-services-square-zuc {
    
        height: 18vh;
     
      
      }
   
      .our-services-content-cert  .our-services-square-zuc button{
        width: 80%;
        border-radius: 30px;
     
        font-size: 0.6rem;
    
       
      }
      .our-services-square-zuc button{
        width: 80%;
        border-radius: 30px;
     
        font-size: 0.8rem;
    
       
      }
      

    }
    @media(max-width:410px){
      #s-title-zuc {
       
     
        margin-top: 0px;
        padding-top: 20px;
       
      }
      
      .our-services-content-cert{
        height: auto;
      }
      .our-services-content-cert .our-services-square-zuc {
        height: 18vh;
      }
      .our-services-content-cert .our-services-square-zuc .our-services-text-zuc  #service-icon-zuc{
      font-size: 1.7rem;
       
      }
      .our-services-content-cert  .our-services-square-zuc button{
     
     
        font-size: 0.6rem;
        width: 90%;
    
       
      }
     
      .zuc-sobre-title h1 {
        font-size: 1.8rem;
        color: #ffff;
        width: 90%;
      }
    
      
      
      .zuc-sobre-title h5 {
        letter-spacing: 3px;
        font-weight: bold;
        font-size: 1rem;
        
      
      }
      #clipLogo{
        width: 40%;
      }

      .our-services-content-zuc {

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4,1fr);
        
     
      }
      .our-services-content-cert {

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3,1fr);
        
     
      }
      .our-services-square-zuc {
    
        height: 16vh;
     
      
      }
    

      
      #s-title-zuc h2 {
 
        font-size: 1rem;

      
      
      }
      
      #s-title-zuc h1 {

        font-size: 2.8rem;
      

      
      
      }
      #service-icon-zuc {

        font-size: 2.1rem;
      

      
      
 
    }
     
    .zuc-details-text button{
      width: 55%;
      margin-top: 10px;
      
    }
     
    .our-services-content-cert .our-services-square-zuc .our-services-text-zuc  #service-title-zuc{
      font-size: 1rem;
       
      }
      

    }
