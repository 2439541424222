.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color: #282e91;
    color: #fff;
    width: 18rem;
    height: fit-content;
    padding: 0 1rem 1rem 1rem;
    border-radius: 10px;

 
  }
  .cardImgDiv{
display: flex;
justify-content: center;

  }
  
  .card .regular {
    margin-top: -30%;
    width: 100%;


  }
  .card .extra {
    margin-top: -40%;
    width: 100%;


  }
  .card h1{
    color: #f2542d;
    margin-top: -20%;
  }
  
  .card h2 {
    color: #f2542d;
    margin: 0;
    margin-top: 1rem;
    letter-spacing: normal;
    font-size: 1.2rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    text-justify: distribute-all-lines;
  }
  
  .btnn {
    display: flex;

    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @media(max-width:500px){
    .card {
   
      width: 14rem;
      height: auto;
      padding: 0 1rem 1rem 1rem;
  
   
    }

    
  
    .card h2 {
      color: #f2542d;
      margin: 0;
      margin-top: 1rem;
      font-size: 1rem;
    }
    
    .card p {
   
      font-size: 0.8rem;
    }
    
    .btnn {

      font-size: 0.8rem;
   
    }

  }