
.home-container-lumi {
    background-image: url('.././assets/lumiligh-capa.jpg');
    background-size: cover;
    background-position: 30% 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   
    background-color: #000;
    
  }

  #container-lumi{
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  #container-lumi span{
    color:#fff;
    border: none;
    
  }

  .lumi-sobre {
    width: 100%;
    height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

    background-color: #f2542d;
    
  }

  .lumi-sobre-container {
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
  }
  
  .lumi-sobre-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
  }
  
  .lumi-sobre-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 30px;

    
  }
  
  .lumi-sobre-title {
    width: 100%;
  }
  
  .lumi-sobre-title h1 {
    font-size: 2.5rem;
    color: #fff;
  }

  
  
  .lumi-sobre-title h5 {
    letter-spacing: 3px;
    font-weight: bold;
    
  
  }
  
  .lumi-sobre-text span {
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
    
  }
  
  .lumi-sobre-text p {
    font-family: "Poppins", sans-serif;
    color: #fff;
  }
  .lumi-sobre-benefits{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 30px;
  }
  .lumi-sobre-benefits h2 {
    font-size: 1.5rem;
    color: #fff;
  }
  .lumi-sobre-benefits ul{
    padding: 5px 0px 0px 0px;
  }
  .lumi-sobre-benefits li{
    padding: 5px 0px 0px 0px;
    color: #fff;
    font-size: 1rem;

  }
  #sobre-icon{
    color: #fff;
    margin-right: 5px;
  }
  .lumi-sobre-button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
   
  }

  .lumi-sobre-button-container img {
    width: 100px;
    height: auto;
    margin-top: 0;
  
  }

  .lumi-sobre-image {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;



  }
  
  #filtro{
    width: 25%;
  }
  .lumi-sobre-image img {
    width: 15%;
    align-items: center;
    justify-content: center;
   
  }
  .logos{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    width: 90%;
  }
  
.our-services-content-lumi {
  width: 80%;
 
  height: 50%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;

}
.our-services-square-lumi {
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  
}
.our-services-icon-lumi {
  display: flex;
  width: 100%;

  justify-content: center;
  column-gap: 10px;



}

.our-services-text-lumi {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;

}

.percentages {

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height:40%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

#img-lumi-1{
  background-image: url('.././assets/economia.png');
}
#img-lumi-2{
  background-image: url('.././assets/desempenho.png');
}
#img-3{
  background-image: url('.././assets/equacao.png');
}
#img-4{
  background-image: url('.././assets/seguranca.png');
}

.service-title-percentage {
  color: #FFF;
  font-size: 1.5em;
  font-weight: 700;

 
}

.our-services-text-lumi h1 {
  position: absolute;
  font-size: 2rem;

}
.our-services-text-lumi span {
 color: #fff;
 text-align: center;
 width: 100%;

}
#center{
  text-align: center;
  width: 100%;

}
@media(max-width:1280px){
  
  .lumi-sobre {

    height: 130vh;

  }
  

  

}
@media(max-width:835px){
  .lumi-sobre {

    height: 100vh;

  }
  .lumi-sobre-content {

    row-gap: 20px;
  
  }
   
  #filtro{
    width: 30%;
  }
  .lumi-sobre-image img {
    width: 20%;
  
   
  }
.our-services-content-lumi {

 
  height: 100%;
width: 90%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  column-gap: 20px;
  padding-bottom: 50px;
  margin-top: 0px;


}

.our-services-square-lumi {
  display: flex;
  width: 100%;
  height: 30vh;
  justify-content: center;
  

}

  .percentages {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  

}

@media(max-width:610px){
  #container-lumi span{
    text-align: center;}
  #container-lumi span{
   
    padding: 10px 0px;
    
  }
  .lumi-sobre-container {
    width: 90%;
    padding: 50px 0px;
   
 
  }
  .lumi-sobre {

    height: 125vh;

  }
  .lumi-sobre-image {
display: none;



  }
  .destaques-container-title #title-lumi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 100vw;
   
  }
  .lumi-sobre-benefits{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: auto,auto,auto;
    row-gap: 5px;
  }
  
  #lumi-services-container{
    height: 100vh;
    row-gap: 50px;
    margin-top: 0px;
  }
  #lumi-services-container .our-services-title{
    text-align: center;

  }
  
  #lumi-services-container .our-services-title{
    margin-top: 50px;
    
  }
  
  #lumi-services-container #service-icon {

    font-size: 2.5em;
  text-align: center;
    color: #f2542d;
  
  
  }
  #lumi-services-container h3{
    font-size: 1.5em;
  }
  
  .our-services-text-lumi h1 {

    font-size: 1.5rem;
  
  }
  .our-services-text-lumi span {
   color: #fff;
   text-align: center;
   width: 100%;
   padding-top: 10px;
  
  }


.our-services-square-lumi {
  display: flex;
  width: 100%;
  height: 30vh;
  justify-content: center;

}

  .percentages {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  

}

@media(max-width:550px){

  #container-lumi span{
   text-align: center;
    font-size: 2.5rem;
    
  }

  .lumi-sobre {

    height: 145vh;

  }
  .lumi-sobre-text {
 
    flex-direction: column;
    justify-content: center;
    column-gap: 30px;

    
  }
  #lumi-services-container{
    height: 120vh;
    
    
  }
.our-services-square-lumi {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;

}

  .percentages {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  
}

@media(max-width:500px){
  #lumi-services-container .our-services-title{
    margin-top: 50px;
    width: 100%;
    
  }

  .lumi-sobre {

    height: auto;

  }

  #lumi-services-container{
    height: 90vh;
    
    
  }
.our-services-square-lumi {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;

}

  .percentages {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-bottom: 0px;
 
  }

  .our-services-text-lumi {
    width: 100%;
    height: auto;
    
  }  
  #lumi-services-container #service-icon {

    font-size: 2.1em;
  text-align: center;
    color: #f2542d;
  
  
  }
  #lumi-services-container h2{
    font-size: 1rem;
  }
  #lumi-services-container >div >h1{
    font-size: 2.5rem;
  }
  #lumi-services-container h3{
    font-size: 1.2em;
  }
  #lumi-services-container span{
    text-align: center;
     font-size: 0.9rem;
     
   }
   .percentages h1{
    font-size: 1.3rem;
    text-align: center;
   }
 

}

@media(max-width:400px){

  #container-lumi span{
   text-align: center;
    font-size: 1.8rem;
    
  }

  .lumi-sobre {

    height: auto;

  }

  
  #lumi-services-container{
    height: 130vh;
    
    
  }
.our-services-square-lumi {
  display: flex;
  width: 100%;
  height: 40vh;
  justify-content: center;

}

.service-title-percentage {
  color: #FFF;
  font-size: 1.5rem;
  font-weight: 700;

 
}
  .percentages {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  
}

@media(max-width:380px){

 
  .lumi-sobre {

    height: 195vh;

  }

  #lumi-services-container{
    height: 130vh;
    
    
  }
.our-services-square-lumi {
  display: flex;
  width: 100%;
  height: 40vh;
  justify-content: center;

}
#lumi-services-container h2{
  font-size: 1rem;
}
#lumi-services-container >div >h1{
  font-size: 2.5rem;
}
#lumi-services-container h3{
  font-size: 1.2em;
}
.service-title-percentage {
  color: #FFF;
  font-size: 1.5rem;
  font-weight: 700;

 
}
  .percentages {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  
}